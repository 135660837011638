import { css, type Theme } from "@emotion/react";

import { dimensions, animations } from "@styles/index";

export const box = {
  base: css`
    border: 1px solid transparent;
  `,
  inline: (theme: Theme) => css`
    border: 1px solid ${theme.system.borderBase};
    box-shadow: none;
  `,
  selected: (theme: Theme) => css`
    border: 1px solid ${theme.system.borderFocused};
    box-shadow: none;
  `,
  disabled: (theme: Theme) => css`
    border: 1px solid ${theme.disabled.border};
  `,
};

export const contentSection = {
  base: css`
    padding: 2rem;

    @media (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1rem;
    }
  `,
  inlineContent: css`
    && {
      padding: 1rem 2rem;
    }
  `,
  heights: css`
    @media (min-width: ${dimensions.tablet}) {
      height: 16rem;
    }
    @media (min-width: ${dimensions.desktop}) {
      height: 18rem;
    }
  `,
};

export const radioLabelOverride = css`
  align-items: center;
`;

export const labelWrapper = css`
  gap: 0;
`;

export const wrapper = {
  default: css`
    border-radius: ${dimensions.cardRadius};

    &:not(:last-child) {
      margin-bottom: ${dimensions.containerMarginMobile};
    }

    &:hover,
    &:focus {
      ${animations.hoverBoxShadow};
    }
    section > label {
      > div + div {
        margin-left: 0;
        margin-right: 2.5rem;
      }
      > input + div,
      > input + span {
        position: absolute;
        top: 1rem;
        right: 1rem;
        margin: 0;
      }

      @media (min-width: ${dimensions.tablet}) {
        > div + div {
          margin-right: 0;
        }
      }
    }
  `,
  checkbox: css`
    section > label {
      position: relative;
    }
  `,
  disabled: css`
    &:hover,
    &:focus {
      box-shadow: none;
    }
  `,
};

export const inlineLabel = {
  baseline: css`
    align-items: baseline;
  `,
  middle: css`
    align-items: center;
  `,
};

export const inlineInput = css`
  section > label input + div {
    position: relative;
    top: initial;
    right: initial;
    left: 1rem;
    bottom: 0.1rem;
    margin-right: 0;
  }
  // Reduce width to match non-isInline Tiles
  section > label > div + div {
    margin-right: -1rem;
    max-width: calc(100% - 1rem);
  }
`;

export const checkboxCentered = css`
  section > label input + div {
    @media (max-width: ${dimensions.phoneLargeMax}) {
      top: auto;
    }
  }
`;

export const fullHeight = css`
  height: 100%;
`;
