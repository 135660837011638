import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { animations } from "@styles";

export const radioWrapper = (theme: Theme) => css`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  position: relative;
  gap: 0.5rem;

  font-size: ${theme.aviaryTypography.body.fontSize};
  font-weight: ${typography.weightBase};
`;

export const hidden = css`
  display: none;
`;

export const disabled = css`
  cursor: not-allowed;
  user-select: none;
`;

export const radio = (theme: Theme) => css`
  position: absolute;
  left: -9999px;

  & + div {
    ${animations.transition()};
  }

  & + div + div {
    color: ${theme.text.body};
  }

  & + div > svg {
    color: ${theme.input.backgroundInformation};
  }

  &:checked + div {
    > svg {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  &:checked + div {
    background-color: ${theme.input.borderActive};
    border-color: ${theme.input.borderActive};
  }
  &:hover + div {
    border-color: ${theme.input.borderEmphasizedHover};
  }
  &:active + div + div {
    color: ${theme.text.body};
  }
  &:hover + div + div {
    color: ${theme.text.emphasis};
  }
  &:focus + div,
  &:active + div {
    border-color: ${theme.input.borderActive};
    border-width: 2px;
  }
  &[disabled] {
    & + div + div {
      color: ${theme.disabled.text};
    }
    &:hover + div + div {
      color: ${theme.disabled.text};
    }
    &:focus + div,
    &:active + div {
      box-shadow: none;
    }
  }
`;

export const iconContainer = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    cursor: pointer;
    // Due to odd rendering issues not respecting width
    width: 16.5px;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 0.5rem;
    border: 1px solid ${theme.input.borderEmphasized};
  `,
  disabled: (theme: Theme) => css`
    cursor: not-allowed;
    & + div {
      cursor: not-allowed;
    }
    &&& {
      border-color: ${theme.disabled.border};
      background-color: ${theme.disabled.inputBackground};
    }
  `,
};

export const icon = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    color: ${theme.input.backgroundInformation};
    height: 0.75rem;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  `,
  disabled: (theme: Theme) => css`
    cursor: not-allowed;
    &&& {
      color: ${theme.disabled.inputLabelText};
    }
  `,
};

export const radioContent = css`
  width: 100%;
`;
